import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";

export default function Projects() {
  const usuarios = [{
    name:'Solimano Miranda Marco',
    seccion:'2',
    birthday:'26/06/1995',
    celular:'+51 994624287',
    correo: 'msolimanomiranda@gmail.com',
    reside: 'Peru - Lima - Los Olivos',
    premda: 'L',
    profesion: 'Ing.Software',
    image: 'solimano.jpg',
    facebook:'https://www.facebook.com/marco.solimano.56/'
  },
  {
    name:'Valverde Mendoza Miguel',
    seccion:'1',
    birthday:'27/08/1994',
    celular:'+51 994624287',
    correo: 'mianvame.1165@hotmail.com',
    reside: 'Peru - Lima - Ate',
    premda: 'M',
    profesion: 'Ingniero',
    image: 'valverde.jpg',
    facebook:'https://www.facebook.com/angeyz.1.0.free'
  },{
    name:'Gonzales Aguilar Ginno',
    seccion:'3',
    birthday:'18/10/1994',
    celular:'+61 456967650',
    correo: 'ginno.gonzales.aguilar57@gmail.com',
    reside: 'Australia - Sydney - Strathfield',
    premda: 'XXL',
    profesion: 'Obrero Calificado',
    image: 'gonzalesGino.jpg',
    facebook: 'https://www.facebook.com/ginno.gonzalesaguilar'
  },{
    name:'Bastidas Orellana Keny',
    seccion:'1',
    birthday:'02/03/1994',
    celular:'+51 970182796',
    correo: 'Keny.bastidas@gmail.com',
    reside: 'Peru - Lima - Lima',
    premda: 'L',
    profesion: 'Ingeniero',
    image: 'kenny.jpg',
    facebook: 'https://www.facebook.com/keny0203'
  }
,{
  name:'Corbetto Egusquiza Carlos',
  seccion:'3',
  birthday:'31/12/1994',
  celular:'+51 990455221',
  correo: 'enriquecorbetto@gmail.com',
  reside: 'Peru - Lima - Lima',
  premda: 'L',
  profesion: 'Oficial del Ejército',
  image: 'corbeto.jpg',
  facebook: 'https://www.facebook.com/aliitoo.corbetto.1'
},
{
  name:'Pomailia Gonzales Frank',
  seccion:'3',
  birthday:'27/06/1995',
  celular:'+51 968476290',
  correo: 'xxxxxxx@gmail.com',
  reside: 'Peru - Lima - Ate',
  premda: 'L',
  profesion: 'Docente',
  image: 'default.png',
  facebook:'https://www.facebook.com/chescolifran'
},
{
  name:'Rojas Aroni Jhonathan',
  seccion:'1',
  birthday:'15/03/1995',
  celular:'+51 989800886  ',
  correo: 'khalessiale20@gmail.com',
  reside: 'Peru - Tacna - Jorge Basadre',
  premda: 'L',
  profesion: 'Oficial del Ejército',
  image: 'default.png',
  facebook: 'https://www.facebook.com/XHONAT'
},
{
  name:'Valdez Trujillano Frank',
  seccion:'5',
  birthday:'27/12/1994',
  celular:'+51 904243716',
  correo: 'franksua12@hotmail.com',
  reside: 'Peru - Ayacucho - Ayacucho',
  premda: 'L',
  profesion: 'Administrador de Empresas',
  image: 'valdez.jpg',
  facebook:'https://www.facebook.com/frank.valdeztrujillano'
}]
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Integrantes</h1>
          </HeaderInfo>
          <div className="row textCenter">
            { usuarios && usuarios.length > 0 &&
            usuarios.map((value,index) => (
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title={value?.name}
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                user={value}
                action={() => alert("clicked")}
              />
            </div>
            ))

            }
     
      
          </div>
   
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
