import React from "react";
import styled from "styled-components";
import Facebook from "../../assets/svg/Services/Facebook";
export default function ProjectBox({ img, title, text, action, user}) {
  const image = require(`../../assets/img/${user?.image}`).default;
  return (
    <Wrapper>
      <ImgBtn className="aniamte pointer" onClick={action ? () => {} : null}>
        <img className="radius8" src={image} alt="project"></img>
      </ImgBtn>
      <h3 className="font20 extraBold">{title}</h3>
      <div style={{textAlign:'left',paddingLeft:'20px'}}>
      <p className="font13">Sección: {user?.seccion}</p>
      <p className="font13">Profesión: {user?.profesion}</p>
      <p className="font13">Reside: {user?.reside}</p>
      <p className="font13">Cumpleaños: {user?.birthday}</p>
      <p className="font13">Celular: {user?.celular}</p>
      <p className="font13">Correo: {user?.correo}</p>
      <p className="font13"><IconStyle><Facebook onClick={()=>(window.open(user.facebook, '_blank'))} /></IconStyle></p>
      </div>
    
    </Wrapper>
  );
}
const IconStyle = styled.div`
  width: 80%;
  @media (max-width: 860px) {
    margin: 0 auto;
       
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;